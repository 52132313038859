<template>
  <div id="about-eestec">
    <div class = "about-EESTEC pt-10 h-screen">
      <div class = "pl-16 sm:pl-24">
         <h1 class="title text-5xl font-extrabold text-dark_red font-montserrat"
             v-html="$t('home.aboutEESTEC.title') + '<br>'">
        </h1>
      </div>
      <Flicking :options="{ renderOnlyVisible: true, preventClickOnDrag: true, autoResize: false,
      bound: true, align: align, resizeOnContentsReady: true,  needPanelThreshold: 0 , panelsPerView: 3}"
      class="h-full w-screen flicking-styling pl-16 sm:pl-24" v-on:changed="onCardSet" v-on:move="onMovement">
        <div v-for="(card,index) in $t('home.aboutEESTEC.cards')"
        class="card flicking-panel relative w-64 h-3/4 flex flex-col text-left
        justify-center mt-14 bg-cover filter blur-xs rounded-2xl
        sm:w-full sm:h-2/3 mix-blend-color bg-blend-overlay mr-8"
        :key="index">
          <div
            class="image flex flex-col content-center relative
            rounded-2xl max-h-4/5 h-screen object-cover box-border"
            :class="{'image-1': index == 0, 'image-2': index == 1, 'image-3': index == 2}"
            :style="{'background-image': true,
                  'max-height': index == currentIndex ? 100 - cardHeightChange + '%' :
                  (index == currentIndex - 1 && direction == -1) ||
                  (index == currentIndex + 1 && direction == 1) ? 80 + cardHeightChange + '%' : '80%'}">
            <div class="bg-black opacity-70 absolute top-0 left-0
                        rounded-2xl w-full h-full object-cover"
            />
            <div class="red-overlay bg-dark_red absolute top-0 left-0
                        rounded-2xl w-full h-full object-covery"
                :style="{opacity: index == currentIndex ? 0.5 - opacity :
                         (index == currentIndex - 1 && direction == -1) ||
                         (index == currentIndex + 1 && direction == 1) ? opacity : 0}"
            />
            <div class="flex flex-col my-auto px-4 gap-y-3 z-10">
              <p class="text-2xl content-start block fontmb-3 text-white
                      font-montserrat font-normal filter-none sm:text-3xl">{{card.title}}</p>
              <p class="text-base text-white
                      font-roboto font-light leading-5 filter-none">{{card.description}}</p>
            </div>
          </div>
        </div>
      </Flicking>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0,
      movement: 0,
      maxMovement: 260, //kind of
      opacity: 0,
      direction: 0,
      cardHeightChange: 0,
    };
  },
  theme: {
    extend: {
      blur: {
        xs: '2px',
      },
    },
  },
  computed: {
    align() {
      return window.innerWidth <= 480 ? 'center' : 'prev';
    },
  },
  methods: {
    onCardSet(e) {
      this.currentIndex = e.index;
      this.movement = 0;
      this.opacity = 0;
      this.cardHeightChange = 0;
    },
    onMovement(e) {
      this.movement += e.axesEvent.delta.flick;
      this.direction = Math.sign(this.movement);
      this.opacity = Math.max(Math.min((Math.abs(this.movement) / this.maxMovement), 0.5), 0);
      this.cardHeightChange = Math.max(Math.min((Math.abs(this.movement) / this.maxMovement) * 34, 20), 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  @media screen and (min-width: 480px) {
    max-width: 16vw;
  }
}
.red-overlay  {
  transition: opacity 100ms ease,
              max-height 100ms ease;
  @media screen and (min-width: 480px) {
    opacity: 0!important;
    &:hover {
      opacity: 0.5!important;
      &~.flex>.text-base {
        display: block;
      }
    }
    // &~.flex>.text-base {
    //   display: none;
    // }
  }
}

.about-EESTEC {
  background-color: #FAF5F5;
  min-height: 740px;
  @media screen and (min-width: 480px) {
    max-height: 1080px;
  }
}

.image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (min-width: 480px) {
    max-height: 100%!important;
  }
}
.image-1 {
  background-position: 20% 0%;
  background-image: url('https://i.imgur.com/RPM9Y80.jpg');

}
.image-2 {
  background-image: url('https://i.imgur.com/GyaYZg0.jpg');
}
.image-3 {
  background-image: url('https://i.imgur.com/4n20cA1.jpg');
}
.flicking-styling {
  @media screen and (min-width: 480px) {
    width: 60vw!important;
  }
}
</style>
