<template>
  <div id="statistics" class="mx-auto sm:mt-40">
    <div class="container sm:mb-24" v-for="(nmb, index) in $t('home.statistics.stats')" :key="index">
      <p class="numbers-statistics font-montserrat font-extrabold text-6xl mr-4 sm:text-7xl">{{ nmb.currentValue }}</p>
      <p class="numbers-text text-2xl sm:w-80">{{ nmb.text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      start: false,
    };
  },
  computed: {
    numbers() {
      return this.$t('home.statistics.stats');
    },
  },
  mounted() {
    const options = {
      root: null,
      thresholds: 0,
      rootMargin: "0px 0px -100px 0px",
    };
    // eslint-disable-next-line no-shadow
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return;
        }
        this.increment();
        observer.unobserve(entry.target);
      });
    }, options);
    const component = document.querySelector(".container");
    observer.observe(component);
  },
  methods: {
    increment() {
      if (!this.start) {
        this.start = true;
        this.numbers.forEach((i) => {
          setInterval(() => {
            if (i.currentValue < i.value) {
              i.currentValue += 1; // eslint-disable-line no-param-reassign
            }
          }, 50);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#statistics {
  background-color: #faf5f5;
}

.numbers-statistics {
  color: #990008;
  text-shadow: 2px 0 #990008;
  text-align: center;
  width: 50%;
}

.numbers-text {
  color: #333333;
  text-align: left;
}

.container {
  display: flex;
  align-items: center;
  padding-top:2em;
  padding-bottom:2em;
  @media screen and (min-width:480px) {
    padding: 0;
  }
}

.container {
  @media screen and (min-width:480px) {
    border-image: linear-gradient(to left, #3333332E 50%, #faf5f5 50%) 100% 1;
    border-bottom: 1px solid #3333332E
  }
  &:not(:last-of-type) {
    border-image: linear-gradient(to left, #3333332E 50%, #faf5f5 50%) 100% 1;
    border-bottom: 1px solid #3333332E
  }
  ;
}

</style>
