<template>
  <div id="home-page">
    <HomeHero/>
    <div class="flex flex-wrap sm:flex-nowrap sm:flex-row-reverse">
      <Statistics/>
      <AboutEESTEC/>
    </div>
    <!-- <Events/> -->
    <Partners/>
  </div>
</template>

<script>
import AboutEESTEC from "../components/home/AboutEESTEC.vue";
// import Events from "../components/home/Events.vue";
import HomeHero from "../components/home/HomeHero.vue";
import Statistics from "../components/home/Statistics.vue";
import Partners from "../components/home/Partners.vue";

export default {
  components: {
    AboutEESTEC,
    // Events,
    HomeHero,
    Statistics,
    Partners,
  },
};
</script>

<style lang="scss" scoped>
//
</style>
