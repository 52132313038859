<template>
  <div id="partners" class=" my-14">
    <!-- am lasat asta aici in caz ca o sa fie nevoie de ea mai incolo, dupa feedback -->
    <h1 class="font-montserrat text-5xl text-dark_red font-extrabold mb-12 text-center sm:mb-20">
      {{ $t("home.partners.header") }}
    </h1>
    <div
      v-for="(partnerType, index) in getPartners"
      :key="index"
    >
      <h2 class="inline bg-dark_red text-white px-8 py-2 text-xl rounded-r-xl
                 sm:text-4xl sm:px-20 sm:inline-block sm:mx-auto sm:relative sm:left-1/2 sm:transform
                 sm:-translate-x-1/2 sm:rounded-xl sm:bg-pink sm:text-dark_red sm:font-bold
                 sub-partner-title">
        <template v-if="$i18n.locale == 'ro'">
          {{ partnerType.nameRo }}
        </template>
        <template v-if="$i18n.locale == 'en'">
          {{ partnerType.nameEn }}
        </template>
      </h2>
      <div class="flex flex-wrap w-screen gap-x-4 px-5 my-10 gap-y-2 justify-center items-center
                  object-contain sm:w-4/5 sm:mx-auto sm:gap-y-0">
        <div
          v-for="(partner, index2) in partnerType.content"
          :key="index2"
        >
          <a :href="partner.link">
            <img loading="lazy" :src="partner.logo" :alt="partner.name"
                class="w-24 min-h-5 sm:w-48 sm:min-h-10"/>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("partnersModule", ["getPartners", "getLoadingPartners"]),
  },
  methods: {
    ...mapActions("partnersModule", ["requestPartners"]),
  },
  mounted() {
    this.requestPartners();
  },
};

</script>

<style lang="scss" scoped>
.sub-partner-title {
  @media screen and (min-width: 480px) {
    &::after {
      content: '';
      position: absolute;
      width: 30%;
      height: 4px;
      left: 50%;
      bottom: -4px;
      background-color: $darker_red;
    }
  }
}
</style>
